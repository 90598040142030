import React                                   from 'react';
import Img                                     from 'gatsby-image';
import { graphql, useStaticQuery }             from 'gatsby';
import { Center, Box, useMediaQuery, Heading } from '@chakra-ui/react';

import ResponsiveIFrame from '@interness/web-core/src/components/structure/iFrame/iFrame';
import Spacer           from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Mounted          from '@interness/web-core/src/components/structure/Mounted/Mounted';

import RolexLayout   from '../../../components/Layout';
import Tracking      from '../../../components/Tracking';
import TwoColumn     from '../../../components/TwoColumn';
import Checkboard    from '../../../components/Checkboard';
import KeepExploring from '../../../components/KeepExploring';

const RolexUhrmacherkunstPage = () => {
  const data = useStaticQuery(query);
  const [portrait] = useMediaQuery('(max-width: 768px)');
  const name = data.directusCompany.display_name;

  const media = {
    cover: {
      portrait: data.coverPortrait.childImageSharp.fluid,
      landscape: data.coverLandscape.childImageSharp.fluid,
    },
    hanswilsdorf: {
      portrait: data.hanswilsdorfPortrait.childImageSharp.fluid,
      landscape: data.hanswilsdorfLandscape.childImageSharp.fluid,
    },
    grundwerte: {
      portrait: data.grundwertePortrait.childImageSharp.fluid,
      landscape: data.grundwerteLandscape.childImageSharp.fluid,
    },
    superlative: {
      portrait: data.superlativePortrait.childImageSharp.fluid,
      landscape: data.superlativeLandscape.childImageSharp.fluid,
    },
    certification: {
      portrait: data.certificationPortrait.childImageSharp.fluid,
      landscape: data.certificationLandscape.childImageSharp.fluid,
    },
    innovation: {
      portrait: data.innovationPortrait.childImageSharp.fluid,
      landscape: data.innovationLandscape.childImageSharp.fluid,
    },
    integration: {
      portrait: data.integrationPortrait.childImageSharp.fluid,
      landscape: data.integrationLandscape.childImageSharp.fluid,
    },
    banner: {
      portrait: data.bannerPortrait.childImageSharp.fluid,
      landscape: data.bannerLandscape.childImageSharp.fluid,
    },
  }
  return (
    <RolexLayout>
      <Tracking pageType={'servicing procedure page'}/>
      <Mounted>
        <section>
          <Img width="100%" height="25vh"
               fluid={portrait ? media.cover.portrait : media.cover.landscape}/>
        </section>
        <Spacer/>
        <section>
          <Center width="90%" margin="auto">
            <Box maxW="650px" w="100%" textAlign="center">
              <Box textTransform="uppercase">
                <p><b>Exzellenz bei der Fertigung</b></p>
                <Box sx={{
                  letterSpacing: '4.5px',
                  fontWeight: '300',
                  lineHeight: '1.2',
                }}>
                  <Heading as="h1" size="lg" sx={{ hyphens: 'auto' }}>ROLEX UHRMACHERKUNST</Heading>
                </Box>
              </Box>
              <p>
                Im Mittelpunkt der Philosophie und Tätigkeiten von Rolex steht eine langfristige Perspektive. Das
                Konzept der Nachhaltigkeit prägt seit jeher die Entwicklung: Die Herstellung klassischer, langlebiger
                Armbanduhren mit dem Anspruch, die Zeit zu überdauern, ist von Anfang an verbunden mit dem Engagement
                für künftige Generationen durch eine Vielzahl von Partnerschaften, Initiativen und Maßnahmen. <br/><br/>
                Dieser Ansatz spiegelt die Philosophie hinter dem Wort „Perpetual“ wider, die die Marke seit ihrer
                Gründung beflügelt, viele geschickte Menschen in die Pflicht nimmt und harte Arbeit erfordert – Tag für
                Tag und Jahr für Jahr. Ein anspruchsvolles, konsequentes Streben. Das unablässige Streben von Rolex nach
                Exzellenz.
              </p>
            </Box>
          </Center>
        </section>
        <Spacer/>
        <section style={{ width: '90%', margin: 'auto' }}>
          <ResponsiveIFrame maxHeight={56.25}
                            src={`https://www.youtube-nocookie.com/embed/OE0E8BmrvnI?rel=0&amp;controls=0`}
                            title={`Rolex Uhrmacherkunst`} type={'youtube'}/>
        </section>
        <Spacer/>
        <Spacer/>
        <section>
          <Center width="90%" margin="auto">
            <Box maxW="650px" w="100%" textAlign="center">
              <Box textTransform="uppercase">
                <Box sx={{
                  letterSpacing: '4.5px',
                  fontWeight: '300',
                  lineHeight: '1.2',
                }}>
                  <Heading as="h2" size="lg" sx={{ hyphens: 'auto' }}>DIE VISION VON HANS WILSDORF, GRÜNDER VON ROLEX</Heading>
                </Box>
              </Box>
              <p>
                Seit 1905 hält Rolex an der ursprünglichen Vision seines Gründers Hans Wilsdorf fest, der in der
                Armbanduhr einen Gegenstand der Zukunft und ein Symbol des modernen Zeitalters sah. Hans Wilsdorf
                revolutionierte die Welt der Uhrmacherkunst, indem er drei große Herausforderungen meisterte: die
                Produktion kleiner, aber präziser Uhrwerke, die Entwicklung eines robusten, wasserdichten Gehäuses zum
                Schutz der Uhrwerke vor äußeren Einflüssen und die Ausstattung der Armbanduhr mit einem automatischen
                Aufzug, der den Trägern im Alltag mehr Komfort bietet.
              </p>
            </Box>
          </Center>
        </section>
        <section>
          <Box width="90%" maxW="1140px" textAlign="center" margin="auto">
            <Img fluid={portrait ? media.hanswilsdorf.portrait : media.hanswilsdorf.landscape}/>
          </Box>
        </section>
        <Spacer/>
        <Spacer/>
        <section>
          <Checkboard title={'DIE GRUNDWERTE DER ROLEX UHRENMANUFAKTUR'} imageSet={media.grundwerte}>
            Jede Rolex Uhr wird mit beständiger Liebe zum Detail konstruiert, gefertigt und getestet. Dieser Ansatz der
            „Superlative“ zeigt sich in einer Reihe grundlegender Eigenschaften, die jede Rolex Armbanduhr auszeichnen:
            Präzision, Wasserdichtheit, Selbstaufzug, Robustheit, Schlichtheit, Handwerkskunst, Komfort und
            Langlebigkeit. Diese maßgeblichen technischen und ästhetischen Prinzipien dienen der Rolex Uhrenmanufaktur
            bei ihrer Arbeit seit jeher als Leitfaden. Sie sind ein Versprechen an den Träger, dass er mit seiner Rolex
            eine außergewöhnliche Armbanduhr besitzt, deren Merkmale Zeugnis von einer einzigartigen Expertise ablegen.
          </Checkboard>
        </section>
        <Spacer/>
        <section style={{ width: '90%', margin: 'auto' }}>
          <ResponsiveIFrame maxHeight={56.25}
                            src={`https://www.youtube-nocookie.com/embed/aHNEZg35ok8?rel=0&amp;controls=0`}
                            title={`Rolex Grundwerte`} type={'youtube'}/>
        </section>
        <Spacer/>
        <Spacer/>
        <section>
          <Checkboard title={'UHRMACHERKUNST DER SUPERLATIVE'} imageSet={media.superlative} inverted>
            Die Zifferblätter der Rolex Uhren tragen die Inschrift „Superlative“. Dieses Prädikat bescheinigt, dass jede
            Armbanduhr vor dem Verlassen der Uhrenmanufaktur eine Reihe besonders anspruchsvoller Tests bestanden hat,
            die
            von Rolex in eigenen Labors sowie nach eigenen, sehr speziellen Kriterien durchgeführt werden. Diese Tests
            werden nicht nur am Kaliber vorgenommen, sondern an der fertig montierten Uhr. Sie garantieren somit
            Leistungen der Superlative in puncto Ganggenauigkeit, Wasserdichtheit, automatischer Aufzug und Gangreserve
            für den täglichen Einsatz.
          </Checkboard>
        </section>
        <Spacer/>
        <Spacer/>
        <section>
          <TwoColumn title={'MEHR ALS EINE ZERTIFIZIERUNG, EIN GEISTESZUSTAND'} imageSet={media['certification']}>
            Im weiteren Sinne können alle Bestandteile einer Rolex Uhr als Komponenten der „Superlative“ bezeichnet
            werden. Tatsächlich wird jede einzelne von ihnen – vom Entwurf bis hin zur Endmontage – kontinuierlichen und
            strengen Tests unterzogen. Der Begriff „Superlative“ bezieht sich somit auf weit mehr als eine
            chronometrische Zertifizierung. Er beschreibt eine Geisteshaltung, die jede Abteilung des Unternehmens
            durchdringt und alle Rolex Beschäftigten – egal, in welcher Funktion sie tätig sind – anspornt.
          </TwoColumn>
        </section>
        <Spacer/>
        <Spacer/>
        <section style={{ width: '90%', margin: 'auto' }}>
          <ResponsiveIFrame maxHeight={56.25}
                            src={`https://www.youtube-nocookie.com/embed/wclKW2Gprco?rel=0&amp;controls=0`}
                            title={`Rolex Grundwerte`} type={'youtube'}/>
        </section>
        <Spacer/>
        <Spacer/>
        <section>
          <Checkboard title={'FORTWÄHRENDE INNOVATION'} imageSet={media.innovation}>
            Spitzenleistungen sind das Ergebnis fortwährenden technologischen Fortschritts und menschlichen Engagements.
            Das Gelernte wird immer wieder hinterfragt, Techniken, Werkzeuge und Tests werden ständig verbessert. Das
            Streben nach Innovation ist der Marke in Fleisch und Blut übergegangen, was auch die mehr als 600
            Patentanmeldungen belegen, die Rolex seit seiner Gründung eingereicht hat.
          </Checkboard>
        </section>
        <Spacer/>
        <Spacer/>
        <section>
          <Checkboard title={'INTEGRATION ALLER BEREICHE DER UHRMACHERKUNST'} imageSet={media.integration} inverted>
            Die schrittweise Integration aller Bereiche der Uhrmacherkunst in seiner Manufaktur versetzt Rolex in die
            Lage, seine eigenen Regeln festzulegen und sein Streben nach Exzellenz unermüdlich vorantreiben. Diese
            Entscheidung zugunsten der Unabhängigkeit prägte die Identität einer Marke an der Schnittstelle zwischen
            ehrwürdiger Tradition und modernster Technologie. Sie findet ihren Widerhall in den vier Produktionsstätten,
            die sich alle in der Schweiz befinden und zusammen mehr als 9.000 Mitarbeiterinnen und Mitarbeiter
            beschäftigen.
          </Checkboard>
        </section>
        <Spacer/>
        <Spacer/>
        <section>
          <Img width="100%" fluid={portrait ? media.banner.portrait : media.banner.landscape}/>
        </section>
        <Spacer/>
        <KeepExploring/>
        <Spacer/>
      </Mounted>
    </RolexLayout>
  )
};

export default RolexUhrmacherkunstPage;

const query = graphql`
    query {
        directusCompany {
            display_name
        }
        coverLandscape: file(name: {eq: "rolex-watchmaking-cover"} relativeDirectory: {eq: "landscape/watchmaking"}) {
            name
            childImageSharp {
                fluid(quality: 100, maxWidth: 2880) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        coverPortrait: file(name: {eq: "rolex-watchmaking-cover_portrait"} relativeDirectory: {eq: "portrait/watchmaking"}) {
            name
            childImageSharp {
                fluid(quality: 100, maxWidth: 750) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        hanswilsdorfLandscape: file(name: {eq: "rolex-watchmaking-the-founding-vision-of-hans-wilsdorf"} relativeDirectory: {eq: "landscape/watchmaking"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        hanswilsdorfPortrait: file(name: {eq: "rolex-watchmaking-the-founding-vision-of-hans-wilsdorf_portrait"} relativeDirectory: {eq: "portrait/watchmaking"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        grundwerteLandscape: file(name: {eq: "rolex-watchmaking-the-cardinal-values-of-the-rolex-manufacture"} relativeDirectory: {eq: "landscape/watchmaking"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        grundwertePortrait: file(name: {eq: "rolex-watchmaking-the-cardinal-values-of-the-rolex-manufacture_portrait"} relativeDirectory: {eq: "portrait/watchmaking"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        superlativeLandscape: file(name: {eq: "rolex-watchmaking-a-superlative-approach-to-watchmaking"} relativeDirectory: {eq: "landscape/watchmaking"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        superlativePortrait: file(name: {eq: "rolex-watchmaking-a-superlative-approach-to-watchmaking_portrait"} relativeDirectory: {eq: "portrait/watchmaking"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        certificationLandscape: file(name: {eq: "rolex-watchmaking-more-than-a-certification-a-state-of-mind"} relativeDirectory: {eq: "landscape/watchmaking"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        certificationPortrait: file(name: {eq: "rolex-watchmaking-more-than-a-certification-a-state-of-mind_portrait"} relativeDirectory: {eq: "portrait/watchmaking"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        innovationLandscape: file(name: {eq: "rolex-watchmaking-innovation-in-motion"} relativeDirectory: {eq: "landscape/watchmaking"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        innovationPortrait: file(name: {eq: "rolex-watchmaking-innovation-in-motion_portrait"} relativeDirectory: {eq: "portrait/watchmaking"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        integrationLandscape: file(name: {eq: "rolex-watchmaking-integrating-all-areas-of-expertise"} relativeDirectory: {eq: "landscape/watchmaking"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        integrationPortrait: file(name: {eq: "rolex-watchmaking-integrating-all-areas-of-expertise_portrait"} relativeDirectory: {eq: "portrait/watchmaking"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        bannerLandscape: file(name: {eq: "rolex-watchmaking-banner-01"} relativeDirectory: {eq: "landscape/watchmaking"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        bannerPortrait: file(name: {eq: "rolex-watchmaking-banner-01_portrait"} relativeDirectory: {eq: "portrait/watchmaking"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;